import { Button, Checkbox, Empty, Input, Skeleton, Table } from "antd"
import { ColumnGroupType, TablePaginationConfig } from "antd/lib/table"
import React, { useEffect } from "react"
import { FilterValue } from "antd/es/table/interface"
import { ColumnsType } from "antd/es/table"
import ActivityIndicator from "../ActivityIndicator/ActivityIndicator"
import EditableCell from "./components/EditableCell"
import EditableRow from "./components/EditableRow"
import { useTranslation } from "react-i18next"
import Divider from "../../styledComponents/CustomDivider/Divider"
import { observer } from "mobx-react"
import { TableConfig } from "./types/TableConfig"
import { SortOrder } from "./types/SortOrder"
import { FilterFilled } from "@ant-design/icons"
import { ColumnFilterItem } from "../../../domain/types/ColumnFilterItem.type"

interface CommonProps<T> {
	columns: ((ColumnGroupType<T> | ColumnsType<T>) & { filterExactText?: boolean })[] | any
	dataSource: any[]
	onSort?: (sortOrder: SortOrder, sortField: string) => void
	onChangePage?: (page: number, pageSize: number, lastPageFetched: number) => void
	onClickRow?: () => void
	loading?: boolean
	renderCustomLoadingComponent?: () => React.ReactElement
	isFetching?: boolean
	disabled?: boolean
	columnFilters?: ColumnFilterItem
	filterFunctionAsync?: (columnKey: string, selectedKeys: string[]) => void
	tableConfig?: TableConfig
	onChange?: (onChangeData: {
		pagination: TablePaginationConfig
		filters: Record<string, FilterValue | null>
		sorter: any
		extra: any
	}) => void
}

interface withEditableCells<T> extends CommonProps<T> {
	editableCells?: {
		handleSave: (record: any) => void
		onAddRow?: () => void
	}
	clickeableRows?: undefined
}

interface withClickableRows<T> extends CommonProps<T> {
	clickeableRows?: true
	editableCells?: undefined
}

type DataTableProps<T> = withEditableCells<T> | withClickableRows<T>

const ClicleableRow = ({ children, ...props }: any) => {
	//TODO: Ufinished functionallity TODO:
	return <tr {...props}>{children}</tr>
}

const DataTable = <T extends any>({
	columns,
	dataSource,
	onChangePage,
	tableConfig = {
		pageSize: 20,
		sort: {
			field: "id",
			order: "descend"
		}
	},
	editableCells,
	clickeableRows,
	onClickRow,
	loading,
	renderCustomLoadingComponent,
	isFetching,
	disabled,
	columnFilters,
	filterFunctionAsync,
	onChange
}: DataTableProps<T>) => {
	const [pageSize, setPageSize] = React.useState<number>(tableConfig.pageSize || 20)
	const [lastPageFetched, setLastPageFetched] = React.useState<number>(1)
	const { t } = useTranslation("dataTable")
	const [filters, setFilters] = React.useState<ColumnFilterItem>()
	const [filterSearchValue, setFilterSearchValue] = React.useState<string>("")
	let components: any

	components = editableCells
		? {
				body: {
					row: EditableRow,
					cell: EditableCell
				}
		  }
		: undefined

	components = clickeableRows
		? {
				body: {
					row: ClicleableRow
				}
		  }
		: editableCells
		? components
		: undefined

	const paginationConfig: TablePaginationConfig = {
		// disabled: isFetching,
		position: ["topCenter", "bottomCenter"],
		locale: { items_per_page: t("paginator.rowsPerPage").toString() },
		onChange: (page, pageSize) => {
			setPageSize(pageSize)
			const lastPage = Math.round(dataSource.length / pageSize)
			if (page > lastPageFetched) setLastPageFetched(lastPage)
			onChangePage && onChangePage(page, pageSize, lastPage)
		},
		pageSize,
		pageSizeOptions: ["20", "30", "50"],
		showSizeChanger: true,
		showTotal: (total, range) =>
			isFetching ? (
				<div style={{ gap: 5, display: "flex" }}>
					<Skeleton.Button active style={{ height: 20, position: "relative", top: 5 }} size="small" />
					<Skeleton.Button active style={{ height: 20, position: "relative", top: 5 }} size="large" />
				</div>
			) : (
				`${range[0]}-${range[1]} ${t("paginator.of")} ${total} ${t("paginator.items")}`
			),
		itemRender: (current, type, originalElement) => {
			if (type === "next") {
				return isFetching ? <ActivityIndicator size="xs" /> : originalElement
			}
			return originalElement
		}
	}

	if (loading) {
		return !renderCustomLoadingComponent ? (
			<div className="table-responsive">
				<Table
					loading={{ size: "large" }}
					dataSource={Array(paginationConfig.pageSize)
						.fill(0)
						.map((_, idx) => ({ key: idx }))}
					pagination={paginationConfig}
					className="ant-border-space"
					size="large"
				/>
			</div>
		) : (
			<div className="table-responsive">
				<Table
					loading={{ indicator: renderCustomLoadingComponent() }}
					dataSource={Array(paginationConfig.pageSize)
						.fill(0)
						.map((_, idx) => ({ key: idx }))}
					pagination={paginationConfig}
					className="ant-border-space"
					size="large"
				/>
			</div>
		)
	}

	const TABLE_COLUMNS: ColumnsType<any> = columns.map((column: any) => {
		let columnConfig: { [param: string]: any } = { ...column }
		if (column.sorteable) {
			column.autoSort = column.autoSort ?? true
			columnConfig = {
				...columnConfig,
				defaultSortOrder: column.autoSort && tableConfig.sort.order,
				// sortOrder: column?.sortOrder ,
				sortDirections: ["ascend", "descend"],
				sorter: (a: any, b: any) => {
					return Number(a[column.key]) - Number(b[column.key])
				}
			}
		}
		if (column.editable) {
			if (!column.inputType) console.warn("You must provide an inputType to use editable columns.")
			columnConfig = {
				...columnConfig,
				onCell: (record: any) => ({
					record,
					inputType: column.inputType,
					editable: column.editable,
					dataIndex: column.dataIndex,
					title: column.title,
					handleSave: editableCells?.handleSave,
					onAddRow: editableCells?.onAddRow
				})
			}
		}
		if (column.filter) {
			if (!filters || filters[column.key]?.length === 0)
				console.error("You must provide filters to use filterable columns.")
			columnConfig = {
				...columnConfig,
				filterSearch: true,
				//@ts-ignore
				filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
					useEffect(() => {
						if (!columnFilters) return
						setFilters(prev => ({ ...prev, [column.key]: columnFilters[column.key] }))
					}, [columnFilters])
					if (!filters || !columnFilters) return null

					const handleConfirm = () => {
						filterFunctionAsync && filterFunctionAsync(column.key, selectedKeys)
						confirm && confirm()
					}

					return (
						<div style={{ borderRadius: 10 }}>
							<div style={{ padding: 5 }}>
								<Input
									value={filterSearchValue}
									style={{ height: 30 }}
									placeholder={`${t("filter.search")} ${column.title.toLowerCase()}`}
									onChange={e => {
										setFilterSearchValue(e.target.value)
										setFilters(prev => {
											const filtered = e.target.value
												? columnFilters[column.key].filter(
														filter =>
															typeof filter.value === "string" &&
															filter.value
																.toUpperCase()
																.includes(e.target.value.toUpperCase())
												  )
												: columnFilters[column.key]
											return { ...prev, [column.key]: filtered }
										})
									}}
								/>
							</div>
							<Divider style={{ marginBottom: 8, marginTop: 8 }} />
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									width: 220,
									maxHeight: 200,
									height: 180,
									overflowY: "scroll"
								}}
							>
								<div style={{ display: "flex", flexDirection: "column", paddingLeft: 8 }}>
									{filters[column.key]?.length ? (
										filters[column.key]?.map((filter, idx) => (
											<Checkbox
												key={idx}
												style={{ margin: "0px 0px 5px 0px" }}
												checked={selectedKeys.includes(filter.value)}
												onClick={() => {
													if (selectedKeys.includes(filter.value)) {
														setSelectedKeys(
															selectedKeys.filter((value: any) => value !== filter.value)
														)
													} else {
														setSelectedKeys([...selectedKeys, filter.value])
													}
												}}
											>
												{filter.text}
											</Checkbox>
										))
									) : (
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												height: 100
											}}
										>
											<Empty
												style={{ top: 40, position: "relative" }}
												imageStyle={{ width: 100, height: 100 }}
												description={t("filter.noData")}
											/>
										</div>
									)}
								</div>
							</div>
							<Divider style={{ marginBottom: 8, marginTop: 5 }} />
							<div style={{ display: "flex", justifyContent: "space-around", padding: 8 }}>
								<Button
									type="primary"
									size="small"
									style={{
										width: 80,
										display: "flex",
										justifyContent: "center",
										alignItems: "center"
									}}
									onClick={() => handleConfirm()}
								>
									{t("filter.confirm")}
								</Button>
								<Button
									size="small"
									style={{
										width: 80,
										display: "flex",
										justifyContent: "center",
										alignItems: "center"
									}}
									onClick={() => {
										setFilterSearchValue("")
										setFilters(prev => ({ ...prev, [column.key]: columnFilters[column.key] }))
										clearFilters && clearFilters()
									}}
								>
									{t("filter.reset")}
								</Button>
							</div>
						</div>
					)
				},
				filterIcon: (filtered: boolean) => (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center"
						}}
					>
						<FilterFilled style={{ width: 20, height: 20, position: "absolute", zIndex: 1, left: 2 }} />
					</div>
				),
				// filters,
				onFilter: (value: any, record: any) => {
					if (typeof record[column.key] == "string") {
						return record[column.key].toUpperCase().indexOf(value.toUpperCase()) === 0
					} else if (typeof record[column.key]?.props?.children == "string") {
						return record[column.key]?.props?.children.toUpperCase().indexOf(value?.toUpperCase()) === 0
					} else if (Array.isArray(record[column.key]?.props?.children)) {
						const recordArr = record[column.key]?.props?.children
						return recordArr.some((item: any) => {
							if (!value || !item) return false

							const children = item.props?.children

							if (!children) return false

							const text = Array.isArray(children) ? children[0]?.props?.children : children

							if (typeof text !== "string") return false

							const filteredText =
								text.includes("-") && !column.filterExactText ? text.split("-")[1]?.trim() : text.trim()

							return filteredText?.toUpperCase().startsWith(value.trim().toUpperCase())
						})
					} else if (typeof record[column.key]?.props?.children == "object") {
						const recordObj = record[column.key]?.props?.children
						return recordObj?.props?.children?.toUpperCase().indexOf(value?.toUpperCase()) === 0
					} else if (typeof record[column.key]?.props?.value == "string") {
						return record[column.key].props.value.toUpperCase().indexOf(value.toUpperCase()) === 0
					}
				},
				...column
			}
		}
		return columnConfig
	})

	// const sortTitleToolTip = t("sorterTipHelp", {
	// 	sortType: t(tableConfig.sort.order === "ascend" ? "descend" : "ascend")
	// })

	return (
		<div>
			<Table
				components={components}
				columns={TABLE_COLUMNS}
				sticky={true}
				// TODO: Fix sorter tooltip locale with current sorter direction state
				// showSorterTooltip={{
				// 	title: sortTitleToolTip,
				// 	overlayStyle: { maxWidth: sortTitleToolTip.length > 30 ? "150px" : undefined }
				// }}
				loading={disabled && { indicator: <div></div> }}
				rowClassName={clickeableRows ? "clicleable-row" : editableCells ? "editable-row" : undefined}
				dataSource={dataSource}
				pagination={paginationConfig}
				className="ant-border-space"
				onRow={() => ({ onClick: () => onClickRow && onClickRow() })}
				scroll={{
					y: "55vh",
					x: "max-content"
				}}
				onChange={(pagination, filters, sorter, extra) => {
					onChange && onChange({ pagination, filters, sorter, extra })
				}}
			/>
		</div>
	)
}

export default observer(DataTable)
